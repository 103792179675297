<template>
  <div>
    <slot name="chart-header"></slot>
    <label v-if="!memberCode">
      <feather-icon icon="AlertTriangleIcon"/> 請選擇會員
    </label>
    <app-echart-line v-else :option-data="chartOptionData"/>
  </div>
</template>

<script>
import AppEchartLine from "@core/components/charts/echart/AppEchartLine.vue"
import kskRecordMixin from "@/views/kiosk/kskRecord/mixins/kskRecordMixin";

export default {
  name: 'TabWeightChart',
  mixins: [kskRecordMixin],
  components: {
    AppEchartLine
  },
  props: {
    memberCode: {
      type: String,
      default: '',
    },
    search_condition: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sort_condition: {},
      page_condition: {
        pageIndex: 0,
        pageSize: 0,
      },
      chartOptionData: {
        xAxisType: "time",
        tooltip: {
          formatter: this.tooltipFormatter
        },
        series: {
          name: this.$t('kiosk.weight'),
          type: "line",
          data: [
            // ['2024/12/7 10:00:00', 36.5],
            // ['2024/12/8 10:00:00', 36.8],
            // ['2024/12/8 10:01:00', 36.3],
            // ['2024/12/9 10:00:00', 36.7],
          ]
        },
      },
    }
  },
  mounted() {
    this.runSearch()
  },

  methods: {
    getList(condition = null) {
      const params = Object.assign(
        { memberCode: this.memberCode },
        this.search_condition,
        this.sort_condition,
        this.page_condition,
        condition,
      )
      if (!params.memberCode) return false

      this.$requestWehealth({
        method: 'get',
        url: '/kioskmanage/GetMeasureWeightList',
        params,
      })
        .then(res => {
          this.total_rows = res.data.total
          this.chartOptionData.series.data = res.data.measureWeightItems
            .map(item => [item.measureTime, item.weight])
        })
    },
    runSearch: function () {
      this.$emit('runSearch', this)
    },
  }
}
</script>

<style scoped>

</style>
