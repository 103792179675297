export default {
  methods: {
    tooltipFormatter: function (params) {
      let html = ""
      if (params.length > 0) {
        const value0Array = params[0].value[0].split("T")
        html += value0Array[0] + " " + value0Array[1]
      }
      params.forEach((p, i) => {
        html += `<br /><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${p.color};"></span>${p.seriesName}: ${p.value[1]}`
      })
      return html
    }
  }
}