<template>
  <div>
    <slot name="chart-header"></slot>
    <label v-if="!memberCode">
      <feather-icon icon="AlertTriangleIcon"/> 請選擇會員
    </label>
    <app-echart-line v-else :option-data="chartOptionData"/>
  </div>
</template>

<script>
import AppEchartLine from "@core/components/charts/echart/AppEchartLine.vue"
import kskRecordMixin from "@/views/kiosk/kskRecord/mixins/kskRecordMixin";

export default {
  name: 'TabBloodPressureChart',
  mixins: [kskRecordMixin],
  components: {
    AppEchartLine
  },
  props: {
    memberCode: {
      type: String,
      default: '',
    },
    search_condition: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sort_condition: {},
      page_condition: {
        pageIndex: 0,
        pageSize: 0,
      },
      chartOptionData: {
        xAxisType: "time",
        tooltip: {
          formatter: this.tooltipFormatter,
        },
        series: [
          {
            name: this.$t('kiosk.systolic'),
            type: 'line',
            data: [
              // ['2023-12-7 10:00:00', 110],
              // ['2023-12-8 10:00:00', 120],
              // ['2023-12-9 10:00:00', 130],
              // ['2023-12-9 10:01:00', 140],
              // ['2023-12-10 10:00:00', 130],
              // ['2023-12-11 10:00:00', 120],
              // ['2023-12-12 10:00:00', 120],
              // ['2023-12-13 10:00:00', 130],
            ]
          },
          {
            name:  this.$t('kiosk.diastolic'),
            type: 'line',
            data: [
             // ['2023-12-7 10:00:00', 90],
             // ['2023-12-8 10:00:00', 80],
             // ['2023-12-9 10:00:00', 80],
             // ['2023-12-9 11:15:00', 90],
             // ['2023-12-10 10:00:00', 100],
             // ['2023-12-11 10:00:00', 90],
             // ['2023-12-12 10:00:00', 90],
             // ['2023-12-13 10:00:00', 100],
            ]
          },
        ],
      },
    }
  },
  mounted() {
    this.runSearch()
  },

  methods: {
    getList(condition = null) {
      const params = Object.assign(
        { memberCode: this.memberCode },
        this.search_condition,
        this.sort_condition,
        this.page_condition,
        condition,
      )
      if (!params.memberCode) return false

      this.$requestWehealth({
        method: 'get',
        url: '/kioskmanage/GetMeasureBPList',
        params,
      })
        .then(res => {
          this.total_rows = res.data.total
          this.chartOptionData.series[0].data = res.data.measureBPItems
            .map(item => [item.measureTime, item.systolic])
          this.chartOptionData.series[1].data = res.data.measureBPItems
            .map(item => [item.measureTime, item.diastolic])
        })
    },
    runSearch: function () {
      this.$emit('runSearch', this)
    },
  }
}
</script>

<style scoped>

</style>
