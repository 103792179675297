<template>
  <e-charts
    ref="line"
    autoresize
    :options="line"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/markLine'
import 'echarts/lib/chart/line'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      line: {
        // Make gradient line here
        visualMap: [{
          show: true,
          type: 'continuous',
          min: 0,
          max: 400,
        }],
        grid: {
          left: '40px',
          top: '30px',
          right: '40px',
          show: false,
        },
        legend: {},
        tooltip: Object.assign({
          trigger: 'axis',
        }, this.optionData.tooltip),
        xAxis: [{
          boundaryGap: false,
          data: this.optionData.xAxisData,
          type: this.optionData.xAxisType,
        }],
        yAxis: {
          type: 'value',
          splitLine: { show: false },
          scale: true, // Should we expose this param?
          boundaryGap: ['10%', '10%'], // Should we expose this param?
        },
        series: this.optionData.series,
      },
    }
  },
}
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
