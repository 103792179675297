<template>
  <div>
    <slot name="chart-header"></slot>
    <label v-if="!memberCode">
      <feather-icon icon="AlertTriangleIcon"/>
      請選擇會員
    </label>
    <app-echart-line v-else :option-data="chartOptionData"/>
  </div>
</template>

<script>
import AppEchartLine from "@core/components/charts/echart/AppEchartLine.vue"
import kskRecordMixin from "./mixins/kskRecordMixin";

export default {
  name: 'TabGlucoseChart',
  mixins: [kskRecordMixin],
  components: {
    AppEchartLine
  },
  props: {
    memberCode: {
      type: String,
      default: '',
    },
    bg_period_map: {
      type: Array,
      default: []
    },
    search_condition: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sort_condition: {},
      page_condition: {
        pageIndex: 0,
        pageSize: 0,
      },
      chartOptionData: {
        xAxisType: "time",
        tooltip: {
          formatter: this.tooltipFormatter,
        },
        series: [...(['KioskHK'].includes(process.env.VUE_APP_API_PROJ_TAG)
            // 血糖類型（布爾值）
            ? [true, false].map(isEmptyStomach => ({
              _code: isEmptyStomach,
              name: isEmptyStomach ? this.$t('kiosk.glucose_type_empty_stomach') : this.$t('kiosk.glucose_type_after_meal'),
              type: 'line',
              data: [],
            }))
            // 血糖類型（枚舉值）
            : this.bg_period_map?.map(bg => ({
              _code: bg.statusCode,
              name: bg.statusDesc,
              type: 'line',
              data: [],
            }))
        )]
      },
    }
  },
  mounted() {
    this.runSearch()
  },

  methods: {
    getList(condition = null) {
      const params = Object.assign(
        { memberCode: this.memberCode },
        this.search_condition,
        this.sort_condition,
        this.page_condition,
        condition,
      )
      if (!params.memberCode) return false

      this.$requestWehealth({
        method: 'get',
        url: '/kioskmanage/GetMeasureGlucoseList',
        params,
      })
        .then(res => {
          this.total_rows = res.data.total
          this.chartOptionData.series.forEach((s, i) => {
            s.data = res.data.measureGlucoseItems
              .filter(item => {
                return ['KioskHK'].includes(process.env.VUE_APP_API_PROJ_TAG)
                  ? item.isEmptyStomach === s._code // 血糖類型（布爾值）
                  : item.measuredPeriod === s._code // 血糖類型（枚舉值）
              })
              .map(item => [item.measureTime, item.glucose])
          })

        })
    },
    runSearch: function () {
      this.$emit('runSearch', this)
    },
  }
}
</script>

<style scoped>

</style>
