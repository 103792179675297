<template>
  <div class="wh-space-bottom">
    <b-card>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </b-card>

    <b-card>
      <b-tabs v-model="tabIndex" lazy @input="onTabChange">
        <b-tab v-for="tab in tabs" :key="tab.name">
          <template #title>
            <feather-icon icon="CpuIcon"/><span>{{ tab.title }}</span>
          </template>
          <div class="tab-content">
            <div class="tab-header" v-if="true">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  v-model="tabConfigs.segmentSelected"
                  :options="tabConfigs.segments"
                  :aria-describedby="ariaDescribedby"
                  name="radios-btn-default"
                  buttons button-variant="outline-primary"
                  value-field="value" text-field="text" disabled-field="disabled"
                />
              </b-form-group>
              <div class="date-range-wrapper">
                <b-form-group>
                  <flat-pickr
                    v-model="date_range_str"
                    ref="dateRangePickr"
                    class="form-control date-range-picker"
                    placeholder="請選擇日期範圍"
                    :disabled="false"
                    :config="{
                      mode: 'range',
                      maxDate: new Date(),
                    }"
                    @on-change="onDateRangeChange"
                  />
                </b-form-group>
                <b-button-group>
                  <b-button
                    :variant="date_range_str === date_range_list.d7 ? 'outline-primary' : 'outline-secondary'"
                    :pressed="date_range_str === date_range_list.d7"
                    @click="() => date_range_str = date_range_list.d7"
                  >近7天
                  </b-button>
                  <b-button
                    :variant="date_range_str === date_range_list.d30 ? 'outline-primary' : 'outline-secondary'"
                    :pressed="date_range_str === date_range_list.d30"
                    @click="() => date_range_str = date_range_list.d30"
                  >近30天
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    @click="() => date_range_str = ''"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </b-button-group>
              </div>
            </div>
            <!-- list segment -->
            <component
              v-if="tabConfigs.segmentSelected === 'list'" ref="tabContents"
              :is="tab.name"
              :isShowDetailCols="isShowDetailCols"
              @runSearch="runSearch"
              :bg_period_map="bg_period_map"
              :data_source_map="data_source_map"
              :search_condition="search_condition"
              :search_content="search_content"
            >
              <template #table-header>
                <table-header
                  ref="header"
                  title=""
                  :searchContentInit="search_content"
                  :hide-title="true"
                  :hide-search="false"
                  :searchTips="$t('kiosk.record_search_tips')"
                  :button-actions="header_actions"
                  :button-actions-on-left="false"
                  @runSearch="(content) => runSearch(null, true, content)"
                />
              </template>
            </component>
            <!-- chart segment -->
            <component
              v-else ref="tabContents"
              :is="tab.name + 'Chart'"
              @runSearch="runSearch"
              :memberCode="memberCode"
              :bg_period_map="bg_period_map"
              :search_condition="search_condition"
            ></component>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import common from '@/common'
import flatPickr from 'vue-flatpickr-component'
import TableSearch from '@/components/TableSearch'
import TableHeader from '@/components/TableHeader'

// Tabs
import TabBloodPressure from './TabBloodPressure'
import TabBloodPressureChart from './TabBloodPressureChart'
import TabGlucose from './TabGlucose.vue'
import TabGlucoseChart from './TabGlucoseChart.vue'
import TabGripStrength from './TabGripStrength'
import TabGripStrengthChart from './TabGripStrengthChart'
import TabHeight from './TabHeight'
import TabHeightChart from './TabHeightChart'
import TabOximetry from './TabOximetry'
import TabOximetryChart from './TabOximetryChart'
import TabTemperature from './TabTemperature'
import TabTemperatureChart from './TabTemperatureChart'
import TabWeight from './TabWeight'
import TabWeightChart from './TabWeightChart'

export default {
  name: 'kioskRecord',
  components: {
    flatPickr,
    TableSearch, TableHeader,

    // Tabs
    TabBloodPressure, TabBloodPressureChart,
    TabGlucose, TabGlucoseChart,
    TabGripStrength, TabGripStrengthChart,
    TabHeight, TabHeightChart,
    TabOximetry, TabOximetryChart,
    TabTemperature, TabTemperatureChart,
    TabWeight, TabWeightChart,
  },

  data() {
    return {
      title: common.getMenuName('kioskRecord'),
      view_permission: common.checkPermission('Kiosk_Menu_Record'),

      refObjs: null,
      memberCode: '',

      // 會員狀態
      status_map: [],

      // 血糖類型
      bg_period_map: [
        { "statusCode": "NS", "statusDesc": "不指定" },
        { "statusCode": "ES", "statusDesc": "空腹" },
        { "statusCode": "BTM", "statusDesc": "餐前" },
        { "statusCode": "ATM", "statusDesc": "餐後" },
        { "statusCode": "BB", "statusDesc": "睡前" }
      ],
      dietary_map: [], // 飲食狀況
      conscious_map: [], // 意識
      emotional_map: [], // 情緒表現
      data_source_map: [], // 數據來源

      // 篩選欄
      search_condition: {},
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'floorID',
          label: 'common.floor',
          type: 'select',
          options: [],
        },
        {
          field: 'roomID',
          label: 'common.room',
          type: 'select',
          options: []
        },
        {
          field: 'memberCode',
          label: 'common.member',
          type: 'select',
          options: [],
          searchable: true
        },
        ['KioskHK'].includes(process.env.VUE_APP_API_PROJ_TAG) ? {
          field: 'dataSource',
          label: 'kiosk.dataSource',
          type: 'select',
          options: [],
        } : null,
      ].filter(item => item !== null),
      search_default: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
        floorID: common.getCustomConfigByKey('floorID'),
        roomID: common.getCustomConfigByKey('roomID'),
        memberCode: common.getCustomConfigByKey('memberCode'),
        dataSource: null,
      },

      // 内容欄-標簽欄
      tabs: [
        {
          name: 'TabTemperature',
          title: '體溫'
        },
        {
          name: 'TabBloodPressure',
          title: '血壓'
        },
        {
          name: 'TabOximetry',
          title: '血氧'
        },
        {
          name: 'TabGlucose',
          title: '血糖'
        },
        {
          name: 'TabWeight',
          title: '體重'
        },
        {
          name: 'TabHeight',
          title: '身高'
        },
        // {
        //   name: 'TabGripStrength',
        //   title: '握力'
        // },
      ],
      tabIndex: common.getCustomConfigByKey('kioskRecordTabIndex') ?? 0,

      // 内容欄-標簽頁
      tabConfigs: {
        segmentSelected: 'list', // ['chart', 'list']
        segments: [
          { text: '列表', value: 'list', disabled: false },
          { text: '圖像', value: 'chart', disabled: false },
        ]
      },
      date_range_str: '', // a string, for v-model
      date_range_list: {
        d7: [this.$moment().subtract(7 - 1, 'day').format('YYYY-MM-DD'), this.$moment()
          .format('YYYY-MM-DD')].join(' to '),
        d30: [this.$moment().subtract(30 - 1, 'day').format('YYYY-MM-DD'), this.$moment()
          .format('YYYY-MM-DD')].join(' to '),
      },

      // 内容欄-標簽頁-列表
      search_content: '',
      isShowDetailCols: common.getCustomConfigs().isShowDetailCols,

    }
  },

  computed: {
    header_actions() {
      return [
        // {
        //   type: 'switch',
        //   tooltipTitle: '展示更多',
        //   variant: 'info',
        //   iconLeft: 'EyeIcon',
        //   iconRight: 'EyeOffIcon',
        //   checked: this.isShowDetailCols,
        //   fun: () => this.$emit('toggleDetailCols'),
        //   permission: 'Kiosk_Menu_Record',
        //   pill: true,
        // },
        {
          text: '展示更多',
          variant: this.isShowDetailCols ? 'info' : 'outline-info',
          fun: this.toggleDetailCols,
          permission: 'Kiosk_Menu_Record',
          pill: true,
        },
      ]
    },
  },

  mounted() {
    if (!common.isAdmin()) {
      common.getSearchFloorOptions('floorID', common.getRole().facilityID, this)
      common.getSearchMemberOptions('memberCode', common.getRole().companyID, common.getRole().facilityID, this)
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
      common.getSearchFloorOptions('floorID', common.getAdminFacilityId(), this)
      common.getSearchMemberOptions('memberCode', common.getAdminCompanyId(), common.getAdminFacilityId(), this)
    }
    common.getSearchRoomOptions('roomID', this.search_default.floorID, this)

    this.$requestWehealth({
      method: 'get',
      url: '/kiosk/GetMeasureDropDownList',
    }).then(({ data }) => {
      this.bg_period_map = data?.bgPeriod ?? []
      this.dietary_map = data?.dietary ?? []
      this.conscious_map = data?.conscious ?? []
      this.emotional_map = data?.emotional ?? []

      // 設置選項：數據來源
      if (['KioskHK'].includes(process.env.VUE_APP_API_PROJ_TAG)) {
        this.data_source_map = data?.dataSource ?? []
        this.search_fields.find(f => f.field === 'dataSource').options = common.getSelectOptions(data?.dataSource, 'statusDesc', 'statusCode')
      }
    })

    this.memberCode = this.$refs.search.list.memberCode
  },

  methods: {
    runSearch(vmOfTab = null, withContent = false, content = this.search_content) {
      vmOfTab = vmOfTab ?? this.$refs.tabContents[0]
      const vmOfSearch = this.$refs.search
      vmOfSearch.buildSearchCondition()
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
        memberCode: vmOfSearch.list.memberCode,
      })

      Object.assign(this.search_condition, this.search_default, vmOfSearch.list)
      this.memberCode = vmOfSearch.list.memberCode

      if (withContent) {
        this.search_content = content
        vmOfTab.getList({
          ...this.search_condition,
          searchContent: this.search_content,
        }, true)
      } else {
        vmOfTab.getList({
          ...this.search_condition,
        }, true)
      }

    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },
    onTabChange(tabIndex) {
      common.syncSearchFields({
        ...common.getCustomConfigs(),
        kioskRecordTabIndex: tabIndex,
      })
    },
    toggleDetailCols() {
      this.isShowDetailCols = !this.isShowDetailCols
      common.assignCustomConfigs({ isShowDetailCols: this.isShowDetailCols })
    },
    onDateRangeChange(selectedDates, dateStr, instance) {
      if (selectedDates.length === 2) {
        Object.assign(this.search_condition, {
          startDate: this.$moment(selectedDates[0]).format('YYYY-MM-DD'),
          endDate: this.$moment(selectedDates[1]).format('YYYY-MM-DD')
        })
        this.runSearch()
      }
      if (selectedDates.length === 0) {
        Object.assign(this.search_condition, {
          startDate: '',
          endDate: '',
        })
        this.runSearch()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$elmHeight: 38px;
.tab-header {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 2rem;
  align-items: center;
  margin-bottom: 1rem;

  .form-group {
    margin: 0;
  }

  ::v-deep .btn {
    height: $elmHeight;
  }

  .date-range-wrapper {
    display: flex;
    width: 34rem;

    .form-control {
      width: 15rem;
      height: $elmHeight;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }

    .btn-group {
      height: $elmHeight;

      > .btn:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
</style>
