<template>
  <div>
    <b-card-title class="font-weight-bolder d-flex flex-wrap align-items-baseline" v-if="!hideTitle">
      <feather-icon v-if="showBack" size="24" class="mr-1" icon="ArrowLeftIcon" @click="$router.back()"/>
      <div class="d-flex align-items-center mr-1">
        {{ title }}
        <b-badge v-if="titleBadge.text" class="ml-1" :variant="titleBadge.variant">{{ titleBadge.text }}</b-badge>
      </div>
      <div v-if="subTitle" class="font-weight-bold font-small-4 mt-05" :class="subTitleClass">{{ subTitle }}</div>
    </b-card-title>

    <b-row v-if="showActionRow">
      <b-col sm="12" md="12" lg="6" class="mb-1">
        <b-input-group class="cursor-pointer" v-if="!hideSearch">
          <b-form-input :placeholder="searchTips" v-model="searchContent" @keyup.enter="runSearch"/>
          <b-input-group-append @click="runSearch">
            <b-button variant="primary">
              <feather-icon icon="SearchIcon"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col
        v-if="buttonActions.length > 0"
        sm="12" md="12" lg="6" class="d-flex mb-1 align-items-center"
        :class="{
        'justify-content-start': buttonActionsOnLeft,
        'justify-content-end': !buttonActionsOnLeft,
      }">
        <template v-for="item in buttonActions">
          <b-form-checkbox
            v-if="item.type === 'switch'" switch inline
            :delay="400"
            :checked="item.checked" name="check-button" class="switch"
            :class="{
              ...item.class,
              'custom-control-primary': item.variant === 'primary',
              'custom-control-secondary': item.variant === 'secondary',
              'custom-control-success': item.variant === 'success',
              'custom-control-danger': item.variant === 'danger',
              'custom-control-warning': item.variant === 'warning',
              'custom-control-info': item.variant === 'info',
              'custom-control-dark': item.variant === 'dark',
              'mr-1': buttonActionsOnLeft,
              'ml-1': !buttonActionsOnLeft,
            }"
            :variant="item.variant"
            :permission="item.permission"
            :style="item.style"
            :disabled="item.disabled"
            v-b-tooltip="{
              title: item.tooltipTitle ? item.tooltipTitle : '',
              trigger: 'hover',
              delay: { show: 400, hide: 200 },
              placement: 'top',
            }"
            @input="item.fun"
          >
            {{ item.text }}
            <span v-if="item.iconLeft" class="switch-icon-left"><feather-icon :icon="item.iconLeft"/></span>
            <span v-if="item.iconRight" class="switch-icon-right"><feather-icon :icon="item.iconRight"/></span>
          </b-form-checkbox>
          <button-permission
            v-else
            :class="{
              ...item.class,
              'mr-1': buttonActionsOnLeft,
              'ml-1': !buttonActionsOnLeft,
            }"
            :variant="item.variant"
            :permission="item.permission"
            :style="item.style"
            :pill="item.pill"
            :disabled="item.disabled"
            @click="item.fun"
          >
            {{ common.getI18n(item.text) }}
          </button-permission>
        </template>
      </b-col>
    </b-row>

  </div>

</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BCardTitle,
  BCardHeader,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend
} from 'bootstrap-vue'
import ButtonPermission from "@/components/ButtonPermission";
import common from "@/common";

export default {
  name: "TableHeader",
  components: {
    ButtonPermission,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BCardTitle,
    BCardHeader,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend
  },
  props: {
    title: { type: String, default: '' },
    titleBadge: {
      type: Object,
      default() {
        return {
          text: '',
          variant: 'secondary'
        }
      }
    },
    subTitle: { type: String, default: '' },
    subTitleClass: { type: String, default: '' },
    buttonActions: { type: Array, default: () => [] },
    buttonActionsOnLeft: { type: Boolean, default: false },
    searchTips: { type: String, default: '' },
    hideTitle: { type: Boolean, default: false },
    hideSearch: { type: Boolean, default: false },
    showBack: { type: Boolean, default: false },
    searchContentInit: { type: String, default: '' }
  },
  data() {
    return {
      searchContentRaw: '',
      search_content: '',
      common,
    }
  },
  computed: {
    showActionRow() {
      return !this.hideSearch || this.buttonActions.length > 0
    },
    searchContent: {
      get() {
        return this.searchContentRaw.trim()
      },
      set(val) {
        this.searchContentRaw = val
      }
    }
  },
  mounted() {
    this.searchContent = this.searchContentInit
  },
  methods: {
    buildSearchContent() {
      this.search_content = this.searchContent
    },
    runSearch: function () {
      this.$emit('runSearch', this.searchContent)
    }
  }
}
</script>

<style lang="scss" scoped>
.switch {
  transform: scale(1.2);
  transform-origin: left;
  height: 1.7rem;
  width: 2.6rem;

  ::v-deep label {
    font-size: 0.857rem;
  }
}
</style>
